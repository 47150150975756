const INTER = [{ space_src: 'baidujs', space_type: 'inters', space_id: 'u6989469', space_js: '//h5fpb1.szcgsoft.cn/common/e/production/zyh/bh/source/v/static/yh.js' }];
const BANNER = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6989470', space_js: '//h5fpb1.szcgsoft.cn/source/vqp_y/openjs/sy_m_rw.js' }];
const NATIVE_1 = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6989471', space_js: '//h5fpb1.szcgsoft.cn/production/w/static/rq/source/ztzns_a.js' }];
const NATIVE_2 = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6989472 ', space_js: '//h5fpb1.szcgsoft.cn/production/xsrau/a_ot_h.js' }];
const NATIVE_3 = [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6989473', space_js: '//h5fpb1.szcgsoft.cn/source/y_t_sb/source/v_bpul.js' }];

/** 广告位配置 */
export const SPACE_MAP = {
    GuideNative: NATIVE_1,
    GuideBanner: BANNER,
    HomeInter: INTER,
    HomeBanner: BANNER,
    HomeNative: NATIVE_1,
    HomeNativeCenter: NATIVE_2,
    HomeNativeBottom: NATIVE_3,
    HotInter: INTER,
    HotBanner: BANNER,
    HotNative: NATIVE_1,
    DetailInter: INTER,
    DetailBanner: BANNER,
    DetailNative: NATIVE_1,
};
