<template>
  <div class="icp">
    <p>ICP备案/许可证号：粤ICP备2024222315号-1</p>
    <p>{{ appName }} - {{ companyName }}</p>
    <DisclaimerPopVue></DisclaimerPopVue>
  </div>
</template>

<script lang="ts">
export default {
  name: "Icp"
};
</script>

<script lang="ts" setup>
import DisclaimerPopVue from "./DisclaimerPop.vue";

const appName = APP_NAME;
const companyName = COMPANY_NAME;
</script>

<style lang="less" scoped>
.icp {
  width: 100%;
  margin-top: 50px;
  p {
    width: 100%;
    text-align: center;
    color: #1c2026;
    font-size: 24px;
  }
  p:nth-of-type(1) {
    margin-bottom: 20px;
  }
}
</style>
